<template>
  <div>
    <v-card class="pb-5 px-5" elevation="0">
      <v-card-title class="justify-center">
        <span class="headline">{{ $t("Notepad_notes") }} </span>
      </v-card-title>
      <v-card-text>
        <center>
          <span class=""><b>{{$t('candidate').substring(0, 9)}}</b>:
            {{ this.$route.params.fullname }}</span>
        </center>
        <center v-if="this.$route.params.title!='null'">
          <span class=""><b>{{$t('vacancy_1').substring(0, 9)}}</b>:
           {{ this.$route.params.title }}</span>
        </center>
        <v-row>
          <v-col cols="12" md="12" class="scroll-test mb-5">
            <div
              class="d-flex flex-column pb-2"
              v-for="item in notes"
              :key="item.time"
            >
              <div>
                <span class="black--text font-weight-bold">{{
                  item.name
                }}</span>
                <span v-if="item.time" class="caption">
                  | {{ formatDate(item.time) }}</span
                >
                <!-- <span v-if="item.ranking" class="caption"> | Ranking</span>
                <span v-else class="caption"> | Candidaturas</span> -->
              </div>
              <div>
                <span class="pt-1">{{ item.text }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-card-text>
      <v-card-actions class="px-5">
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { GET_NOTES_VACANCY_CANDIDATE_QUERY } from "../modules/vacancy/graphql/Query.resolver";
import moment from "moment";
export default {
  data: () => ({
    note: null,
    notes: [],
    vacancy: [],
    ranking: false,
  }),
  apollo: {
    notes: {
      query: GET_NOTES_VACANCY_CANDIDATE_QUERY,
      variables() {
        return {
          vacancyId: this.$route.params.vacancyId
            ? this.$route.params.vacancyId
            : this.$route.params.vacancyId,
          candidateId: this.$route.params.candidateId,
        };
      },
      fetchPolicy: "no-cache",
      result({ data }) {
        if (this.$route.params.type === "Ranking") {
          this.ranking = true;
        }
        console.log("data", data);
        this.vacancy = data;
        this.notes = data.notes.filter((note) => note.ranking == this.ranking);
        // this.notes = data.notes
      },
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        const d = new Date(date).getTime();
        moment.locale(this.$i18n.locale);
        return moment(d).calendar();
      }
      return "";
    },
  },
  created(){
    this.$i18n.locale = localStorage.getItem("lang");
    // console.log('locale', localStorage.getItem("lang"));
  }
};
</script>
<style  scoped>

</style>

